/* RESPONSIBLE TEAM: team-standalone */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export default class FinStandaloneDeployPhoneRoute extends Route {
  @service declare router: RouterService;
  @service declare appService: any;
  @service declare finStandaloneService: FinStandaloneService;
  @service declare store: Store;

  get app() {
    return this.appService.app;
  }

  beforeModel() {
    if (!this.appService.app.canUseStandaloneDeployPhone) {
      this.router.transitionTo('apps.app.standalone.deploy.overview');
    }
  }
}
